<template>
  <v-row class="pt-4">
    <v-col cols="12" xl="9" class="text-justify">
      <v-form
        ref="form"
        v-model="valid"
      >
      <v-card flat class="transparent">
        <v-card-text>
          <v-row>
            <InputField
              :key="i"
              :field="field"
              v-model="formData[field.key]"
              v-for="(field, i) in formFields"
            />
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="primary"
            class="text-none"
            @click="goBack"
          >
            {{ $t('labels.exit') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="primary"
            class="text-none"
            :disabled="!valid"
            @click="saveRecord(true)"
            v-if="!planid"
          >
            {{ $t('labels.saveExit') }}
          </v-btn>
          <v-btn
            color="primary"
            class="text-none"
            :disabled="!valid"
            @click="saveRecord(false)"
            v-if="!planid"
          >
            {{ $t('labels.saveAddNew') }}
          </v-btn>
          <v-btn
            color="primary"
            class="text-none"
            :disabled="!valid"
            @click="updateRecord(true)"
            v-if="planid"
          >
            {{ $t('labels.update') }}
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';
import InputField from './input/InputField.vue';

export default {
  name: 'PlanningForm',
  props: {
    editdata: {
      type: Object,
      default: null,
    },
    planid: {
      type: String,
      default: null,
    },
    jsonData: {
      type: Object,
    },
  },
  components: {
    InputField,
  },
  data() {
    return {
      formData: {},
      valid: false,
    };
  },
  computed: {
    formFields() {
      return this.jsonData.fields;
    },
    element() {
      return this.jsonData.elementName;
    },
  },
  mounted() {
    if (this.editdata) {
      this.formData = this.editdata;
    }
  },
  created() {
    if (!this.planid) {
      this.resetForm();
    }
  },
  methods: {
    ...mapActions('receivingProductionVista', ['createRecord', 'updateRecordById']),
    async saveRecord(exit) {
      const formData = {
        ...this.formData,
        assetid: 11,
        status: false,
        scheduledstart: new Date().valueOf(),
      };
      await this.createRecord({ payload: formData, elementName: this.element });
      if (exit) {
        this.goBack();
      }
      this.$refs.form.reset();
    },
    async updateRecord(exit) {
      const formData = {
        ...this.formData,
      };
      delete formData.createdTimestamp;
      delete formData.modifiedtimestamp;
      await this.updateRecordById({
        elementName: this.element,
        payload: formData,
        id: this.planid,
      });
      if (exit) {
        this.goBack();
      }
      this.$refs.form.reset();
    },
    resetForm() {
      // eslint-disable-next-line
      this.jsonData.fields.map((f) => this.formData[f.key] = '');
    },
    goBack() {
      this.$router.push({ name: 'receivingProductionVista' });
    },
  },
};
</script>
