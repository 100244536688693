import { render, staticRenderFns } from "./CreateNew.vue?vue&type=template&id=396424b6&scoped=true"
import script from "./CreateNew.vue?vue&type=script&lang=js"
export * from "./CreateNew.vue?vue&type=script&lang=js"
import style0 from "./CreateNew.vue?vue&type=style&index=0&id=396424b6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../infinity/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "396424b6",
  null
  
)

export default component.exports