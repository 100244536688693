var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"py-0",attrs:{"cols":_vm.field.cols}},[(_vm.field.inputtype === 'text')?_c('v-text-field',{attrs:{"outlined":"","color":"blue darken-2","label":_vm.field.lable,"rules":_vm.field.rules ? _vm.parseRules(_vm.field.rules) : [],"disabled":_vm.field.disabled},on:{"input":function($event){return _vm.$emit('input', _vm.inputvalue)}},model:{value:(_vm.inputvalue),callback:function ($$v) {_vm.inputvalue=$$v},expression:"inputvalue"}}):_vm._e(),(_vm.field.inputtype === 'integer')?_c('v-text-field',{attrs:{"outlined":"","color":"blue darken-2","label":_vm.field.lable,"rules":_vm.field.rules ? _vm.parseRules(_vm.field.rules) : [
        v => !!v || `${_vm.field.lable} is required`,
        v => /^\d+$/.test(v) || `${_vm.field.lable} is Number`
      ],"disabled":_vm.field.disabled},on:{"input":function($event){return _vm.$emit('input', _vm.inputvalue)}},model:{value:(_vm.inputvalue),callback:function ($$v) {_vm.inputvalue=$$v},expression:"inputvalue"}}):_vm._e(),(_vm.field.inputtype === 'float')?_c('v-text-field',{attrs:{"outlined":"","color":"blue darken-2","label":_vm.field.lable,"rules":_vm.field.rules ? _vm.parseRules(_vm.field.rules) : [
        v => !!v || `${_vm.field.lable} is required`,
        v => /^[+-]?\d+(\.\d+)?$/.test(v) || `${_vm.field.lable} is Decimal`
      ],"disabled":_vm.field.disabled},on:{"input":function($event){return _vm.$emit('input', _vm.inputvalue)}},model:{value:(_vm.inputvalue),callback:function ($$v) {_vm.inputvalue=$$v},expression:"inputvalue"}}):_vm._e(),(_vm.field.inputtype === 'date')?_c('v-menu',{attrs:{"min-width":"auto","nudge-right":40,"transition":"scale-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","disabled":_vm.field.disabled,"label":_vm.field.lable,"prepend-inner-icon":"mdi-calendar","rules":_vm.field.required ? [v => !!v || `${_vm.field.lable} is required`] : []},model:{value:(_vm.inputvalue),callback:function ($$v) {_vm.inputvalue=$$v},expression:"inputvalue"}},'v-text-field',attrs,false),on))]}}],null,false,3994991961),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"min":_vm.minDate},on:{"input":function($event){_vm.menu = false},"change":function($event){return _vm.$emit('input', _vm.inputvalue)}},model:{value:(_vm.inputvalue),callback:function ($$v) {_vm.inputvalue=$$v},expression:"inputvalue"}})],1):_vm._e(),(_vm.field.inputtype === 'select')?_c('v-select',{attrs:{"outlined":"","items":_vm.itemList,"label":_vm.field.lable,"item-text":_vm.field.itemText,"item-value":_vm.field.itemValue,"rules":_vm.field.required ? [v => !!v || `${_vm.field.lable} is required`] : [],"disabled":_vm.field.disabled,"return-object":_vm.field.returnObject},on:{"change":function($event){return _vm.$emit('input', _vm.inputvalue)}},model:{value:(_vm.inputvalue),callback:function ($$v) {_vm.inputvalue=$$v},expression:"inputvalue"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }