<template>
  <div style="height:100%">
    <portal to="app-header">
      <v-btn
        icon
        class="mb-1"
        @click="goBack"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span>{{ $t('editreceiving') }}</span>
    </portal>
    <BaseLoading v-if="loading"></BaseLoading>
    <PlanForm :editdata="formData" :planid="id" :jsonData="jsonData" v-else />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PlanForm from '../components/PlanForm.vue';
import BaseLoading from '../components/base/loading.vue';
import { edit } from '../components/planform.json';

export default {
  name: 'EditPlan',
  components: {
    PlanForm,
    BaseLoading,
  },
  data() {
    return {
      formData: null,
      loading: true,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    element() {
      return edit.elementName;
    },
    jsonData() {
      return edit;
    },
  },
  mounted() {
    this.fetchRecord();
  },
  methods: {
    ...mapActions('receivingProductionVista', ['getRecordsById']),
    goBack() {
      this.$router.push({ name: 'receivingProductionVista' });
    },
    async fetchRecord() {
      this.loading = true;
      this.formData = await this.getRecordsById({ elementName: this.element, id: this.id });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.border-round {
  border-radius: 10px;
}
#home-container {
  height: calc(100vh - 220px);
  overflow: auto;
}
</style>
