<template>
  <v-col :cols="field.cols" class="py-0">
<!-- input text -->
    <v-text-field
      outlined
      color="blue darken-2"
      :label="field.lable"
      v-model="inputvalue"
      @input="$emit('input', inputvalue)"
      v-if="field.inputtype === 'text'"
      :rules="field.rules ? parseRules(field.rules) : []"
      :disabled="field.disabled"
    />

<!-- input integer -->
    <v-text-field
      outlined
      color="blue darken-2"
      :label="field.lable"
      v-model="inputvalue"
      @input="$emit('input', inputvalue)"
      v-if="field.inputtype === 'integer'"
      :rules="field.rules ? parseRules(field.rules) : [
        v => !!v || `${field.lable} is required`,
        v => /^\d+$/.test(v) || `${field.lable} is Number`
      ]"
      :disabled="field.disabled"
    />

    <!-- <v-text-field
      outlined
      color="blue darken-2"
      :label="field.lable"
      v-model="inputvalue"
      @input="$emit('input', inputvalue)"
      v-if="field.inputtype === 'integer'"
      :rules="field.required ? [
        v => !!v || `${field.lable} is required`,
        v => /^\d+$/.test(v) || `${field.lable} is Number`
      ] : []"
      :disabled="field.disabled"
    /> -->

<!-- input float -->
    <v-text-field
      outlined
      color="blue darken-2"
      :label="field.lable"
      v-model="inputvalue"
      @input="$emit('input', inputvalue)"
      v-if="field.inputtype === 'float'"
      :rules="field.rules ? parseRules(field.rules) : [
        v => !!v || `${field.lable} is required`,
        v => /^[+-]?\d+(\.\d+)?$/.test(v) || `${field.lable} is Decimal`
      ]"
      :disabled="field.disabled"
    />

    <!-- <v-text-field
      outlined
      color="blue darken-2"
      :label="field.lable"
      v-model="inputvalue"
      @input="$emit('input', inputvalue)"
      v-if="field.inputtype === 'float'"
      :rules="field.required ? [
        v => !!v || `${field.lable} is required`,
        v => /^[+-]?\d+(\.\d+)?$/.test(v) || `${field.lable} is Decimal`
      ] : []"
      :disabled="field.disabled"
    /> -->

<!-- date picker -->
    <v-menu
      v-model="menu"
      min-width="auto"
      :nudge-right="40"
      transition="scale-transition"
      :close-on-content-click="false"
      v-if="field.inputtype === 'date'"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          outlined
          :disabled="field.disabled"
          v-on="on"
          v-bind="attrs"
          :label="field.lable"
          v-model="inputvalue"
          prepend-inner-icon="mdi-calendar"
          :rules="field.required ? [v => !!v || `${field.lable} is required`] : []"
        />
      </template>
      <v-date-picker
        :min="minDate"
        v-model="inputvalue"
        @input="menu = false"
        @change="$emit('input', inputvalue)"
      />
    </v-menu>
<!-- dropdown list -->
    <v-select
      outlined
      :items="itemList"
      :label="field.lable"
      v-model="inputvalue"
      :item-text="field.itemText"
      :item-value="field.itemValue"
      @change="$emit('input', inputvalue)"
      v-if="field.inputtype === 'select'"
      :rules="field.required ? [v => !!v || `${field.lable} is required`] : []"
      :disabled="field.disabled"
      :return-object="field.returnObject"
    />
  </v-col>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'InputField',
  props: ['field', 'value'],
  data() {
    return {
      inputvalue: '',
      menu: false,
      itemList: [],
    };
  },
  computed: {
    minDate() {
      const today = new Date();
      return today.toISOString().substr(0, 10);
    },
  },
  watch: {
    value(v) {
      this.inputvalue = v;
    },
  },
  mounted() {
    this.inputvalue = this.value;
    if (this.field.inputtype === 'select') {
      this.fetchList();
    }
  },
  methods: {
    ...mapActions('receivingProductionVista', ['getRecords']),
    async fetchList() {
      const res = await this.getRecords(this.field.element);
      if (res) {
        this.itemList = res;
      }
    },
    parseRules(rules) {
      // eslint-disable-next-line
      return rules.map((rule) => eval(`(${rule})`));
    },
  },
};
</script>
